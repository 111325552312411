<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        Mata Pelajaran
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h2 class="lead"><b>{{ soal.name }}</b></h2>
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right">
                    		<router-link to='/soal-mapel' class="btn btn-sm btn-secondary">
                            <i class="fas fa-arrow-left"></i> Ke Daftar Mapel
                    		</router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
			<table class="table table-hover" ref="tblujian">
			<thead>
				<tr>
				<th>SOAL LATIHAN</th>
                <th>DURASI</th>
                <th>HASIL</th>
                <th>TINDAKAN</th>
				</tr>
			</thead>
			<tbody @click="handleClick"></tbody>
			</table>
        </div>
    </section>
</template>
<script>
	import { createTable, authFetch } from "@/libs/hxcore";
    export default {
    name: 'SoalMapelList',
    data: () => ({
      soal: {},
	  roles: "",
    }),
    created: function () {
        let id = this.$route.params.id;
        this.loadMapel(id);

		this.roles = this.$route.meta.roles;
    	console.log("load initial data", this.$route);
    },
    methods: {
        loadMapel(id){
			authFetch("/siswa/ujian/latihan_list/" + id).then((res) => {
				res.json().then((json) => {
				this.soal = json;
				console.log(this.soal);
				});
			});
        },
		handleClick(e) {
			if (e.target.matches(".link-role")) {
				this.$router.push({ path: "/permit/" + e.target.dataset.id });
				return false;
			}

			if (e.target.closest("button")) {
				if (e.target.dataset.action == "view") {
					this.$router.push("/soal-start/" + e.target.dataset.id);
				}
			}
		},
    },
    mounted(){
	const e = this.$refs;
	let self = this;

	this.table = createTable(e.tblujian, {
		title: "",
		language: {
			zeroRecords: "Tidak ada soal latihan."
		},
		selectedRowClass: '',
		roles: ["read"],
		ajax: "/siswa/ujian/soal_latihan_list",
		searching: true,
		paramData: function(d) {
			d.mapel_id = self.$route.params.id;
		},
		columns: [
			{ data: "ujian_title" },
			{ data: "durasi",
			render: function (data, type, row, meta) {
				return `<span class="badge bg-danger">60 Menit</span>`;
			}, },
			{ data: "hasil",
			render: function (data, type, row, meta) {
				return `<div class="progress progress-xs">
                          <div class="progress-bar progress-bar-danger" style="width: 55%"></div>
                        </div>`;
			}, },
			{ data: "action",
			sortable: false,
			render: function (data, type, row, meta) {
				return `<div class="btn-group"><button type="button" class="btn btn-sm btn-outline-info" data-action="view" data-id="`+row.id+`"><i class="fas fa-eye"></i> Mulai</button></div>`;
			}, },
		],
		filter: false
		// filterBy: [0],
	});
    },
}
</script>
<style type="text/css" scoped>
</style>